import React, { useRef, useState } from 'react';
import { FiEdit, FiFilter, FiSettings } from 'react-icons/fi';
import { FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { Input, Select } from '../../components/unform/index';
import api from '../../services/api';
import getQuery from '../../utils/getQuery';
import useValidator from '../../hooks/useValidator';

function Servicos() {
  const { showLoader, closeLoader } = useValidator();
  let debounce = null;
  const filtrosRef = useRef();

  const [result, setResult] = useState({
    items: [],
    pages: 0,
    count: 0,
    itemsPorPagina: 0,
  });

  function filtrarPesquisa() {
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      filtrosRef.current.submitForm();
    }, 500);
  }

  const getData = async (page = 1) => {
    try {
      showLoader();
      const query = getQuery(filtrosRef.current.getData());
      const { data } = await api.get(
        `/produtoservico?tipo=servico&page=${page}&${query}`
      );

      setResult({
        pages: Number(data.pages),
        count: Number(data.count),
        items: data.items,
      });
    } catch (err) {
      toast.error(err.mensagem);
    } finally {
      closeLoader();
    }
  };
  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Serviços</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {result?.count}</h6>
          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter color="rgba(var(--theme-color-rgb), 0.6)" size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <Link className="btn btn-primary" to="/servicos/novo" color="primary">
            Adicionar
          </Link>
        </div>
        <div className="filter-collapse px-3 pb-3">
          <UncontrolledCollapse toggler="#toggler">
            <Form
              onSubmit={() => {
                getData();
              }}
              ref={filtrosRef}
            >
              <Row>
                <Col>
                  <Input
                    className="form-control"
                    placeholder="Digite o nome"
                    label="Nome"
                    name="nome"
                    onChange={filtrarPesquisa}
                  />
                </Col>
                <Col>
                  <Select
                    name="status"
                    className="form-control"
                    label="Status"
                    onChange={filtrarPesquisa}
                    data={[
                      {
                        value: '',
                        label: 'Selecione',
                      },
                      {
                        value: 'Ativo',
                        label: 'Ativo',
                      },
                      {
                        value: 'Inativo',
                        label: 'Inativo',
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div>

        <Table className="rwd-table" responsive>
          <thead>
            <tr>
              <th>Nome</th>
              <th>URL Imagem</th>
              <th>Status</th>
              <th className="text-end">Ação</th>
            </tr>
          </thead>
          <tbody>
            {result?.items?.map((m) => (
              <tr key={m?.id}>
                <td data-label="Nome">{m?.nome}</td>
                <td data-label="URL Imagem">{m?.img_url}</td>
                <td data-label="Status">{m?.status}</td>
                <td
                  data-label="Action"
                  className="d-flex justify-content-lg-end justify-content-sm-between"
                >
                  <UncontrolledDropdown>
                    <DropdownToggle
                      color="link"
                      className="text-decoration-none justify-between"
                    >
                      <FiSettings className="mr-2" /> <FaChevronDown />
                    </DropdownToggle>
                    <DropdownMenu end container="body">
                      <Link to={`/servicos/novo?id=${m?.id}`}>
                        <DropdownItem>
                          <FiEdit className="mr-1" />
                          Editar
                        </DropdownItem>
                      </Link>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={result.pages}
          onPageChange={({ selected }) => getData(selected + 1)}
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
    </>
  );
}

export default Servicos;
