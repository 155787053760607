import { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

export default function Checkbox({ name, value, label, ...rest }) {
  const inputRef = useRef();
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const defaultChecked = defaultValue || (typeof value === 'boolean' && value);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => ref.current.checked,
      clearValue: (ref) => {
        ref.current.checked = defaultChecked;
      },
      setValue: (ref, val) => {
        ref.current.checked = val;
      },
    });
  }, [defaultValue, fieldName, registerField, defaultChecked]);

  return (
    <div>
      <input
        className="inp-cbx"
        defaultChecked={defaultChecked}
        ref={inputRef}
        value={value}
        type="checkbox"
        id={fieldName}
        {...rest}
      />
      <label className="cbx" htmlFor={fieldName} key={fieldName}>
        <div className="d-flex">
          {/* <span className="align-items-center d-flex mt-2">
            <svg width="12px" height="10px">
              <svg id="check" viewBox="0 0 12 10">
                <polyline points="1.5 6 4.5 9 10.5 1" />
              </svg>
            </svg>
          </span> */}
          <span className="py-2 px-2">{label}</span>
        </div>
      </label>

      {error && <span>{error}</span>}
    </div>
  );
}
