import { useQuery } from '@tanstack/react-query';
import { useParams, Link } from 'react-router-dom';
import React, { useEffect, useMemo } from 'react';
import api from '../../services/api';
import scrollToTop from '../../utils/scrollToTop';
import { useAuth } from '../../contexts/auth';

async function getProdutos(categoria = '', subcategoria, marca) {
  const { data } = await api.get(
    `/produtoservico?categoria=${categoria}&id_sub_categoria=${subcategoria}&id_marca=${marca}`
  );

  return data;
}
async function getMarca(marca) {
  const { data } = await api.get(`/marca/${marca}`);

  return data;
}

function HomeProdutos() {
  const params = useParams();
  const { addItemCarrinho } = useAuth();

  const subcategoria = useMemo(() => {
    const arr = String(params?.subcategoria).split('-');

    const id = arr.shift();
    return {
      id,
      nome: arr.join('-'),
    };
  }, [params?.subcategoria]);

  const marca = useMemo(() => {
    const arr = String(params?.marca).split('-');

    const id = arr.shift();
    return {
      id,
      nome: arr.join('-'),
    };
  }, [params?.marca]);

  const { data: dadosMarca } = useQuery({
    queryKey: [`marca:${marca.id}`],
    queryFn: () => getMarca(marca.id),
  });
  const { data } = useQuery({
    queryKey: [`marca:${marca.id}:${subcategoria.id}:produtos`],
    queryFn: () => getProdutos(params?.categoria, subcategoria?.id, marca.id),
  });

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div>
      <div className="container mx-auto rounded-2xl overflow-hidden mt-24">
        <section>
          <h1 className="block antialiased tracking-normal font-sans leading-[1.3] font-normal">
            {
              dadosMarca?.subcategorias?.find(
                (f) => f?.Subcategoria?.value === Number(subcategoria?.id)
              )?.Subcategoria?.label
            }
            {' | '}
            {dadosMarca?.marca?.nome || marca.nome}
          </h1>
        </section>
        <section className="py-8 lg:py-16">
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {!data?.items &&
              [1, 2, 3, 4].map((m) => (
                <div
                  className="animated-background p-3 overflow-hidden rounded-xl"
                  key={m}
                >
                  <div className="relative bg-clip-border  mt-4 mx-4 rounded-xl overflow-hidden !m-0 !mb-6 h-64 p-3" />
                  <p className="text-white block mt-4 mb-3 antialiased font-sans text-xl font-normal leading-relaxed">
                    &nbsp;
                  </p>
                </div>
              ))}
            {data?.items?.map((m, i) => (
              <div
                className="scale-hover touchable p-3 bg-white rounded-xl"
                key={m?.nome || i}
                type="button"
              >
                <p className="text-black block mb-0 antialiased font-sans text-xl font-medium">
                  {m?.nome}
                </p>
                <Link to={`/produto/${m.id}-${m.nome}`}>
                  <div className="relative bg-clip-border mt-4 mx-4 rounded-xl overflow-hidden !m-0 !mb-6 h-64 p-3">
                    <img
                      src={m?.img_url}
                      alt={m?.nome || 'produto'}
                      className="h-full w-full object-fit object-center"
                    />
                  </div>
                </Link>
                <div className="d-flex justify-content-between">
                  <Link
                    className="btn btn-primary-outline btn-round font-semibold"
                    to={`/produto/${m.id}-${m.nome}`}
                  >
                    + Detalhes
                  </Link>
                  <button
                    className="btn btn-primary-light btn-round font-semibold"
                    type="button"
                    onClick={() => {
                      addItemCarrinho({ ...m, descricao: null });
                    }}
                  >
                    Comprar
                  </button>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}

export default HomeProdutos;
