import { useQuery } from '@tanstack/react-query';
import { useParams, Link } from 'react-router-dom';
import React, { useEffect, useMemo } from 'react';
import api from '../../services/api';
import scrollToTop from '../../utils/scrollToTop';
import { useAuth } from '../../contexts/auth';

async function getProduto(id) {
  const { data } = await api.get(`/produtoservico/${id}`);

  return data;
}

function HomeProdutoDetalhe() {
  const params = useParams();
  const { addItemCarrinho } = useAuth();
  const prod = useMemo(() => {
    const arr = String(params?.produto).split('-');

    const id = arr.shift();
    return {
      id,
      nome: arr.join('-'),
    };
  }, [params?.produto]);

  const { data } = useQuery({
    queryKey: [`produtos:${prod.id}`],
    queryFn: () => getProduto(prod.id),
  });

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div>
      <div className="container overflow-hidden mt-24 mb-24">
        <div className="row">
          <div className="col-12 col-lg-6">
            {!data ? (
              <div className="animated-background img-produto rounded-2xl w-90" />
            ) : (
              <img
                className="img-produto rounded-2xl w-90"
                alt="foto produto"
                src={data?.img_url}
              />
            )}
          </div>
          <div className="col-12 col-lg-6">
            {!data ? (
              <>
                <div
                  className="animated-background mb-5"
                  style={{ height: 90 }}
                />
                <div
                  className="mt-5 animated-background"
                  style={{ height: 350 }}
                />
              </>
            ) : (
              <>
                <h2>{data?.nome}</h2>
                <pre
                  className="mt-4 pre-wrap"
                  dangerouslySetInnerHTML={{
                    __html: data?.descricao,
                  }}
                />
                <button
                  className="btn btn-primary btn-round px-8 py-3 mt-4 font-semibold"
                  type="button"
                  onClick={() => {
                    addItemCarrinho({ ...data, descricao: null });
                  }}
                >
                  {data?.tipo !== 'servico' ? 'Comprar' : 'Obter cotação'}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeProdutoDetalhe;
