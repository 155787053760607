import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import Swal from 'sweetalert2';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Card, Button, CardBody } from 'reactstrap';
import {
  Input,
  Select,
  Image,
  TextArea,
  ReactSelect,
} from '../../components/unform';
import api from '../../services/api';

function Marca() {
  const [loading, setLoading] = useState(false);
  const [optionsSubcategorias, setOptionsSubcategorias] = useState([]);
  const formRef = useRef();
  const history = useHistory();
  const location = useLocation();

  async function handleSubmit(data) {
    const query = {};

    location.search
      .substring(1)
      .split('&')
      .forEach((f) => {
        const [key, value] = f.split('=');
        if (value) {
          query[key] = value;
        }
      });
    if (loading) {
      return;
    }
    const errors = {};

    Object.keys(data).forEach((f) => {
      if (!data[f]) {
        if (f !== 'file' && query.id) {
          errors[f] = 'Obrigatório';
        }
      }
    });

    formRef.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      try {
        setLoading(true);
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
          formData.append(key, value);
        });
        Swal.fire({
          title: 'Aguarde',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        if (query.id) {
          await api.put(`/marca/${query.id}`, formData);
          toast.success('Marca alterada com sucesso!');
        } else {
          await api.post('/marca', formData);
          toast.success('Marca criado sucesso!');
        }

        setLoading(false);
        Swal.close();
        history.push('/marcas');
      } catch (err) {
        if (err.response?.data?.errors) {
          formRef.current.setErrors(err.response?.data?.errors);
        }
        Swal.close();
        setLoading(false);
        toast.error(err.msg);
      }
    }
  }

  useEffect(() => {
    async function getSubCategorias() {
      const { data } = await api.get('/subcategorias?options=true');
      setOptionsSubcategorias(data);
    }

    getSubCategorias();
  }, []);

  useEffect(() => {
    async function getData(id) {
      try {
        Swal.fire({
          title: 'Aguarde',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        const { data } = await api.get(`/marca/${id}`);

        document.querySelector('textarea').style.height = '100px';
        if (data?.marca?.categoria) {
          const array = data?.marca?.categoria?.split(',');

          const ar = [];
          array?.forEach((a) => {
            if (a === 'mobile') {
              ar.push({
                value: 'mobile',
                label: 'Mobile',
              });
            }
            if (a === 'voip') {
              ar.push({
                value: 'voip',
                label: 'VoIP',
              });
            }
            if (a === 'informatica') {
              ar.push({
                value: 'informatica',
                label: 'Informática',
              });
            }
          });
          data.marca.categoria = ar;
        }
        if (data?.subcategorias) {
          const arraySubcategorias = [];
          data?.subcategorias?.forEach((e) => {
            arraySubcategorias.push({
              value: e?.Subcategoria?.value,
              label: e?.Subcategoria?.label,
            });
          });

          data.marca.subcategorias = arraySubcategorias;
        }
        formRef?.current?.setData({
          ...data?.marca,
          file: data?.marca?.img_url,
        });
        Swal.close();
      } catch (err) {
        Swal.close();
        toast.error('Ocorreu um erro tente novamente mais tarde');
        history.goBack();
      }
    }
    const query = {};

    location.search
      .substring(1)
      .split('&')
      .forEach((f) => {
        const [key, value] = f.split('=');
        if (value) {
          query[key] = value;
        }
      });

    if (query.id) {
      getData(query.id);
    }
  }, [location]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Nova marca</h4>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <Form
            ref={formRef}
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Row className="justify-center">
              <Col lg={4}>
                <Image name="file" label="Foto" />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={4}>
                <Input name="nome" label="Nome" className="form-control" />
              </Col>
              <Col lg={4}>
                <ReactSelect
                  isMulti
                  name="categoria"
                  label="Categoria"
                  placeholder="Selecione"
                  isClearable
                  options={[
                    { value: 'voip', label: 'VoIP' },
                    { value: 'mobile', label: 'Mobile' },
                    { value: 'informatica', label: 'Informática' },
                  ]}
                />
              </Col>
              <Col lg={4}>
                <ReactSelect
                  isMulti
                  name="subcategorias"
                  label="Subcategorias"
                  placeholder="Selecione"
                  isClearable
                  options={optionsSubcategorias}
                />
              </Col>
              <Col lg={4}>
                <Select
                  name="status"
                  label="Status"
                  className="form-control form-select"
                  data={[
                    { value: 'Ativo', label: 'Ativo' },
                    { value: 'Inativo', label: 'Inativo' },
                  ]}
                />
              </Col>
              {/* <div className="col-lg-12">
                <TextArea
                  name="descricao"
                  label="Descrição"
                  className="form-control"
                />
              </div> */}
            </Row>
            <div className="d-flex justify-content-end mt-3 mb-3">
              <Button className="btn btn-primary" type="submit">
                Salvar
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </>
  );
}

export default Marca;
