import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { Button, Card, Col, Row } from 'reactstrap';

import { toast } from 'react-toastify';
import { Select, Editor } from '../../components/unform';
import api from '../../services/api';

function TextosCategorias() {
  const formRef = useRef();
  const [categoria, setCategoria] = useState();

  useEffect(() => {
    async function getData() {
      const { data } = await api.get(
        `/textos-categorias?categoria=${categoria}`
      );
      formRef.current.setFieldValue('descricao', data?.descricao);
    }
    if (categoria) {
      getData();
    }
  }, [categoria]);

  async function handleSubmit(data) {
    const errors = {};

    Object.keys(data).forEach((f) => {
      if (!data[f]) {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (Object.keys(errors).length) {
      toast.error('Verifique os campos obrigatórios!');
      return;
    }

    try {
      await api.post('/textos-categorias', data);

      toast.success('Dados salvos com sucesso!');
    } catch (error) {
      toast.error('Erro ao slavar dados!');
    }
  }

  return (
    <>
      <Form
        ref={formRef}
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <Row>
          <Col lg={12}>
            <h4 className="mb-3">Início</h4>
          </Col>
        </Row>
        <Card className="px-3 py-3">
          <Row>
            <Col lg={12}>
              <Select
                name="categoria"
                label="Categoria"
                className="form-control"
                onChange={(e) => {
                  formRef.current.setFieldValue('descricao', '');
                  setCategoria(e?.target?.value);
                }}
                data={[
                  {
                    label: 'Selecione',
                    value: '',
                  },
                  {
                    label: 'Mobile',
                    value: 'mobile',
                  },
                  {
                    label: 'VoIP',
                    value: 'voip',
                  },
                  {
                    label: 'Informática',
                    value: 'informatica',
                  },
                  {
                    label: 'Serviços',
                    value: 'servicos',
                  },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Editor
                readOnly={!categoria}
                name="descricao"
                label="Descrição"
              />
            </Col>
          </Row>
        </Card>
        <Row>
          <Col lg={12} className="d-flex justify-content-end">
            <Button color="primary" type="submit">
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default TextosCategorias;
