import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'reactstrap';
import api from '../../services/api';

function Dashboard() {
  const [infos, setInfos] = useState({
    marcas: 0,
    produtos: 0,
    servicos: 0,
  });

  async function getDados() {
    const { data } = await api.get('/dashboard');
    setInfos(data);
  }
  useEffect(() => {
    getDados();
  }, []);

  return (
    <>
      <Row>
        <Col lg={4}>
          <Card>
            <div className="p-3">
              <h5 className="txt-muted text-center">MARCAS CADASTRADAS</h5>
              <h2 className="text-center">{infos?.marcas}</h2>
            </div>
          </Card>
        </Col>
        <Col lg={4}>
          <Card>
            <div className="p-3">
              <h5 className="txt-muted text-center">PRODUTOS CADASTRADOS</h5>
              <h2 className="text-center">{infos?.produtos}</h2>
            </div>
          </Card>
        </Col>
        <Col lg={4}>
          <Card>
            <div className="p-3">
              <h5 className="txt-muted text-center">SERVIÇOS CADASTRADOS</h5>
              <h2 className="text-center">{infos?.servicos}</h2>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Dashboard;
