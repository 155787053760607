import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Row, Col, Card, Button, CardBody } from 'reactstrap';
import { Form } from '@unform/web';
import api from '../../services/api';

import {
  Input,
  Select,
  Image,
  TextArea,
  ReactSelect,
} from '../../components/unform';

function Conteudos() {
  const [dados, setDados] = useState({
    count: 0,
    items: [],
    pages: 1,
    page: 1,
  });
  const [load, setLoad] = useState({
    page: 1,
    hasMore: true,
  });
  const history = useHistory();

  async function handleSubmit(e) {
    try {
      const filtros = Object.entries(e).reduce((a, [key, value]) => {
        if (value) {
          return `${a}&${key}=${value}`;
        }

        return a;
      }, '');
      const { data } = await api.get(`/conteudos?${filtros}`);
      setLoad({
        page: 1,
        hasMore: data.items.length,
      });
      setDados({ ...data, filtros });
    } catch (err) {
      toast.error(err.msg);
    }
  }

  async function loadMore(e) {
    try {
      const { data } = await api.get(
        `/conteudos?page=${load.page + 1}&${dados.filtros || ''}`
      );
      setLoad({
        page: load.page + 1,
        hasMore: data.items.length,
      });
      setDados((old) => ({
        ...old,
        items: old.items.concat(data.items),
      }));
    } catch (err) {
      toast.error(err.msg);
    }
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await api.get('/conteudos');
        setLoad({
          page: 1,
          hasMore: data.items.length,
        });
        setDados(data);
      } catch (err) {
        toast.error(err.msg);
      }
    };
    getData();
  }, []);

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Conteúdos</h4>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const data = {};
              Array.from(e?.target)
                .slice(0, 3)
                .forEach((m) => {
                  data[m.id] = m.value;
                });

              handleSubmit(data);
            }}
          >
            <Row>
              <Col md={4}>
                <label htmlFor="titulo" className="form-label">
                  Título
                </label>
                <input className="form-control" id="titulo" />
              </Col>
              <Col md={2}>
                <label htmlFor="tipo" className="form-label">
                  Tipo
                </label>
                <select className="form-select form-control" id="tipo">
                  <option value="noticia">Notícia</option>
                  <option value="blog">Blog</option>
                </select>
              </Col>
              <Col md={2}>
                <label htmlFor="categoria" className="form-label">
                  Status
                </label>
                <select className="form-select form-control" id="status">
                  <option>Ativo</option>
                  <option>Inativo</option>
                  <option value="todos">Todos</option>
                </select>
              </Col>
              <Col md={4} className="d-flex justify-content-end">
                <div>
                  <div>
                    <label className="form-label"> &nbsp;</label>
                  </div>
                  <button type="submit" className="btn btn-success">
                    Consultar
                  </button>
                </div>
                <div className="ml-3">
                  <div>
                    <label className="form-label"> &nbsp;</label>
                  </div>
                  <Button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      history.push('/conteudos/novo');
                    }}
                  >
                    Novo
                  </Button>
                </div>
              </Col>
            </Row>
          </form>

          <Row className="mt-3">
            {dados.items.map((m) => (
              <Col lg={6} className="mb-3">
                <button
                  type="button"
                  className="btn-none w-100 grow h-100"
                  onClick={() => {
                    history.push(`/conteudos/novo?id=${m.id}`);
                  }}
                >
                  <Card>
                    <Row>
                      <Col lg={5}>
                        <img
                          crossOrigin="anonymous"
                          src={m.img_url}
                          alt={m.titulo}
                          width="100%"
                          className="object-fit"
                        />
                      </Col>
                      <Col lg={7} className="d-flex align-items-center">
                        <h5 className="text-center mb-0 w-100">
                          <small>{m.titulo}</small>
                        </h5>
                      </Col>
                    </Row>
                  </Card>
                </button>
              </Col>
            ))}
            {!!load.hasMore && (
              <Col
                lg={12}
                className="mb-3 d-flex align-items-center justify-content-center"
              >
                <span>
                  <button
                    type="button"
                    className="btn btn-primary-light align-items-center d-flex"
                    onClick={() => {
                      loadMore();
                    }}
                    style={{
                      maxHeight: '100%',
                    }}
                  >
                    <i
                      className="fa-regular fa-plus mr-3"
                      style={{
                        fontSize: 24,
                      }}
                    />
                    <span>Carregar mais itens</span>
                  </button>
                </span>
              </Col>
            )}
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

export default Conteudos;
