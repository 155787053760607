import React, { useEffect } from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';
import { useAuth } from '../contexts/auth';

import AuthRoute from './authRoute';

import Login from '../pages/Login/index';
import Dashboard from '../pages/Dashboard/index';
import Components from '../pages/Dashboard/criar';
import Listar from '../pages/Dashboard/listar';
import Layout from '../layouts';
import LayoutHome from '../layouts/home';
import Home from '../pages/Home';
import HomeMarcas from '../pages/Home/marcas';

import QuemSomos from '../pages/QuemSomos/index';
import Marcas from '../pages/Marcas/listar';
import AdicionarMarca from '../pages/Marcas/adicionar';

import Produtos from '../pages/Produtos/listar';
import AdicionarProduto from '../pages/Produtos/adicionar';

import SubCategoria from '../pages/SubCategoria/listar';
import NovoSubCategoria from '../pages/SubCategoria/adicionar';

import Servicos from '../pages/Servicos/listar';
import AdicionarServicos from '../pages/Servicos/adicionar';

import HomeProdutos from '../pages/Home/produtos';
import HomeProdutoDetalhe from '../pages/Home/produto';

import UsuariosInternos from '../pages/UsuariosInternos/listar';
import CriarUsuariosInternos from '../pages/UsuariosInternos/criar';

import MudarSenha from '../pages/AlterarSenha/index';
import HomeCarrinho from '../pages/Home/carrinho';
import HomeSubCategoria from '../pages/Home/subcategoria';
import HomeCompraFinalizada from '../pages/Home/compraFinalizada';

import Contato from '../pages/Contato/index';
import TextosCategorias from '../pages/TextosCategorias';

import Conteudos from '../pages/Conteudos/conteudos';
import NovoConteudo from '../pages/Conteudos/NovoConteudo';

import Noticias from '../pages/Blognoticias/noticias';
import Conteudo from '../pages/Blognoticias/index';
import Blog from '../pages/Blognoticias/blog';
//
const Routes = () => {
  const { signed } = useAuth();
  const location = useLocation();

  // Initialize Google Analytics
  ReactGA.initialize(process.env.REACT_APP_GA_CODE);
  const trackPage = (page) => {
    ReactGA.set({ page });
    ReactGA.pageview(page);
  };

  useEffect(() => {
    const page = location.pathname;
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Switch>
      {signed ? (
        <>
          <Layout>
            <Switch>
              <AuthRoute path="/alterasenha" exact component={MudarSenha} />
              <AuthRoute
                path="/usuariosinternos"
                exact
                component={UsuariosInternos}
              />
              <AuthRoute
                path="/usuariosinternos/novo"
                exact
                component={CriarUsuariosInternos}
              />
              <AuthRoute path="/textos" exact component={QuemSomos} />
              <AuthRoute
                path="/textos-categorias"
                exact
                component={TextosCategorias}
              />
              <AuthRoute path="/servicos" exact component={Servicos} />
              <AuthRoute
                path="/servicos/novo"
                exact
                component={AdicionarServicos}
              />
              <AuthRoute path="/subcategorias" exact component={SubCategoria} />
              <AuthRoute
                path="/subcategoria/novo"
                exact
                component={NovoSubCategoria}
              />
              <AuthRoute path="/marcas" exact component={Marcas} />
              <AuthRoute path="/marcas/novo" exact component={AdicionarMarca} />
              <AuthRoute path="/produtos" exact component={Produtos} />
              <AuthRoute
                path="/produtos/novo"
                exact
                component={AdicionarProduto}
              />
              <AuthRoute path="/" exact component={Dashboard} />
              <AuthRoute path="/listar" exact component={Listar} />
              <AuthRoute path="/criar" exact component={Components} />

              <Route path="/conteudos" exact component={Conteudos} />
              <Route path="/conteudos/novo" exact component={NovoConteudo} />

              <Redirect to="/" />
            </Switch>
          </Layout>
        </>
      ) : (
        <>
          <Switch>
            <Route path="/login" exact component={Login} />
            <LayoutHome>
              <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/contato" exact component={Contato} />
                <Route path="/carrinho" exact component={HomeCarrinho} />
                <Route
                  path="/compra-finalizada"
                  exact
                  component={HomeCompraFinalizada}
                />
                <Route path="/shop/:categoria" exact component={HomeMarcas} />
                <Route
                  path="/shop/:categoria/:marca"
                  exact
                  component={HomeSubCategoria}
                />
                <Route
                  path="/shop/:categoria/:marca/:subcategoria"
                  exact
                  component={HomeProdutos}
                />
                <Route
                  path="/produto/:produto"
                  exact
                  component={HomeProdutoDetalhe}
                />

                <Route path="/noticias" exact component={Noticias} />
                <Route path="/blog" exact component={Blog} />
                <Route path="/conteudo/:slug" exact component={Conteudo} />
              </Switch>
            </LayoutHome>
          </Switch>
        </>
      )}
    </Switch>
  );
};

export default Routes;
