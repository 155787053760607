import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'reactstrap';
import api from '../../services/api';

function Noticias() {
  const [dados, setDados] = useState({
    count: 0,
    items: [],
    pages: 1,
    page: 1,
  });

  async function loadMore(e) {
    try {
      const { data } = await api.get(
        `/conteudos?page=${dados.page + 1}&${dados.filtros || ''}`
      );
      setDados((old) => ({
        ...old,
        items: old.items.concat(data.items),
      }));
    } catch (err) {
      toast.error(err.msg);
    }
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await api.get('/conteudos');
        setDados(data);
      } catch (err) {
        toast.error(err.msg);
      }
    };
    getData();
  }, []);
  return (
    <>
      <div className="container">
        <h1 className="text-header my-3">
          <strong>Notícias</strong>
        </h1>

        <Card>
          <Row className=" mt-3 mb-3 justify-content-center">
            {dados.items.map((m) => (
              <Col lg={10} className="hr-bottom pb-3">
                <Link
                  className="btn-none w-100 grow h-100 text-decoration-none"
                  to={`/conteudo/${m?.slug}`}
                >
                  <div className="h-100">
                    <Row>
                      <Col lg={5}>
                        <img
                          crossOrigin="anonymous"
                          src={m.img_url}
                          alt={m.titulo}
                          width="100%"
                          style={{
                            height: '150px !important',
                          }}
                          className="object-cover"
                        />
                      </Col>
                      <Col lg={7}>
                        <p className="text-muted">
                          {new Date(m?.created_at).toLocaleDateString('pt-BR')}
                        </p>
                        <h5 className="mb-0 w-100">{m.titulo}</h5>
                      </Col>
                    </Row>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
          <Row>
            {dados?.page && dados?.page < dados.pages && (
              <Col
                lg={12}
                className="mb-3 d-flex align-items-center justify-content-center"
              >
                <span>
                  <button
                    type="button"
                    className="btn btn-primary-light align-items-center d-flex"
                    onClick={() => {
                      loadMore();
                    }}
                    style={{
                      maxHeight: '100%',
                    }}
                  >
                    <i
                      className="fa-regular fa-plus mr-3"
                      style={{
                        fontSize: 24,
                      }}
                    />
                    <span>Carregar mais itens</span>
                  </button>
                </span>
              </Col>
            )}
          </Row>
        </Card>
      </div>
    </>
  );
}

export default Noticias;
