import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import Swal from 'sweetalert2';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Card, Button, CardBody } from 'reactstrap';
import { Input, Select, Image, Editor } from '../../components/unform';
import api from '../../services/api';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn button btn-success',
    cancelButton: 'button btn btn-danger',
  },
  buttonsStyling: false,
});

function Servico() {
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [marcas, setMarcas] = useState([
    { value: '', label: 'Selecione a categoria' },
  ]);
  const [optionsSubcategorias, setOptionsSubcategorias] = useState([]);

  const formRef = useRef();
  const history = useHistory();
  const location = useLocation();

  async function handleSubmit(data) {
    const query = {};

    location.search
      .substring(1)
      .split('&')
      .forEach((f) => {
        const [key, value] = f.split('=');
        if (value) {
          query[key] = value;
        }
      });
    if (loading) {
      return;
    }
    const errors = {};

    Object.keys(data).forEach((f) => {
      if (!data[f]) {
        if (f !== 'file' && query.id) {
          errors[f] = 'Obrigatório';
        }
      }
    });

    formRef.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      try {
        setLoading(true);
        data.tipo = 'produto';
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
          formData.append(key, value);
        });
        Swal.fire({
          title: 'Aguarde',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        if (query.id) {
          await api.put(`/produtoservico/${query.id}`, formData);
          toast.success('Produto alterado com sucesso!');
        } else {
          await api.post('/produtoservico', formData);
          toast.success('Produto criado sucesso!');
        }

        setLoading(false);
        Swal.close();
        history.push('/produtos');
      } catch (err) {
        if (err.response?.data?.errors) {
          formRef.current.setErrors(err.response?.data?.errors);
        }
        Swal.close();
        setLoading(false);
        toast.error(err.msg);
      }
    }
  }

  async function deleteServico() {
    if (!update) {
      return;
    }
    try {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await api.delete(`/produtoservico/${update}`);
      Swal.close();
      toast.warn('Produto excluído com sucesso!');
      history.push('/produtos');
    } catch (err) {
      Swal.close();
      toast.error(err.msg);
    }
  }

  async function handleDelete() {
    swalWithBootstrapButtons
      .fire({
        title: 'Tem certeza que deseja excluir este produto?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
        html: 'Esta operação não poderá ser revertida',
      })
      .then(async (result) => {
        if (result.value) {
          await deleteServico();
        }
      });
  }

  async function getMarcasCategoria(cat) {
    if (cat) {
      try {
        const { data } = await api.get(`/marca?categoria=${cat}&options=true`);
        if (data) {
          setMarcas(data);
        } else {
          setMarcas([{ value: '', label: 'Selecione a categoria' }]);
          toast.warning('Sem marcas cadastradas para essa categoria');
        }
      } catch (error) {
        toast.error('Erro ao buscar marcas!');
      }
    } else {
      setMarcas([{ value: '', label: 'Selecione a categoria' }]);
    }
  }

  useEffect(() => {
    async function getSubCategorias() {
      const { data } = await api.get('/subcategorias?options=true');
      setOptionsSubcategorias(data);
    }

    getSubCategorias();
  }, []);

  useEffect(() => {
    async function getData(id) {
      try {
        Swal.fire({
          title: 'Aguarde',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        const { data } = await api.get(`/produtoservico/${id}`);
        await getMarcasCategoria(data?.categoria);

        if (data?.Marca) {
          formRef?.current?.setFieldValue('id_marca', data?.Marca?.value);
        }
        formRef?.current?.setData({
          ...data,
          file: data?.img_url,
        });
        Swal.close();
      } catch (err) {
        Swal.close();
        toast.error('Ocorreu um erro tente novamente mais tarde');
        history.goBack();
      }
    }
    const query = {};

    location.search
      .substring(1)
      .split('&')
      .forEach((f) => {
        const [key, value] = f.split('=');
        if (value) {
          query[key] = value;
        }
      });

    if (query.id) {
      setUpdate(query.id);
      getData(query.id);
    }
  }, [location]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Novo produto</h4>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <Form
            ref={formRef}
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Row className="justify-center">
              <Col lg={4}>
                <Image name="file" label="Foto" />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={6}>
                <Input name="nome" label="Nome" className="form-control" />
              </Col>
              <Col lg={3}>
                <Select
                  name="categoria"
                  label="Categoria"
                  className="form-control form-select"
                  data={[
                    { value: '', label: 'Selecione' },
                    { value: 'mobile', label: 'Mobile' },
                    { value: 'voip', label: 'VoIP' },
                    { value: 'informatica', label: 'Informática' },
                  ]}
                  onChange={(e) => getMarcasCategoria(e?.target?.value)}
                />
              </Col>
              <Col lg={3}>
                <Select
                  name="id_marca"
                  label="Marca"
                  className="form-control form-select"
                  data={marcas}
                />
              </Col>
              <Col lg={3}>
                <Select
                  name="id_sub_categoria"
                  label="Subcategoria"
                  placeholder="Selecione"
                  className="form-control form-select"
                  data={optionsSubcategorias}
                />
              </Col>
              <Col lg={3}>
                <Select
                  name="status"
                  label="Status"
                  className="form-control form-select"
                  data={[
                    { value: 'Ativo', label: 'Ativo' },
                    { value: 'Inativo', label: 'Inativo' },
                  ]}
                />
              </Col>
              <div className="col-lg-12">
                <Editor
                  name="descricao"
                  label="Descrição"
                  className="form-control"
                />
              </div>
            </Row>
            <div className="d-flex justify-content-end mt-3 mb-3">
              {!!update && (
                <Button
                  className="btn btn-danger mr-3"
                  type="button"
                  onClick={() => {
                    handleDelete();
                  }}
                >
                  Excluir
                </Button>
              )}
              <Button className="btn btn-primary" type="submit">
                Salvar
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </>
  );
}

export default Servico;
