/* eslint-disable react/no-array-index-key */
import { useQuery } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import banner from '../../assets/images/banner.jpeg';
import logo from '../../assets/images/logo-sm.png';
import bannerL from '../../assets/images/banner-l.png';
import doidera from '../../assets/images/marca-dagua.png';
import api from '../../services/api';
import scrollToTop from '../../utils/scrollToTop';

import acp from '../../assets/images/clientes/acp.png';
import agepar from '../../assets/images/clientes/agepar.png';
import baldussi from '../../assets/images/clientes/baldussi.png';
import barinsul from '../../assets/images/clientes/barinsul.png';
import bb from '../../assets/images/clientes/bb.jpg';
import brb from '../../assets/images/clientes/brb.png';
import britania from '../../assets/images/clientes/britania.png';
import carrier from '../../assets/images/clientes/carrier.png';
import copel from '../../assets/images/clientes/copel.png';
import directcall from '../../assets/images/clientes/directcall.png';
import fasano from '../../assets/images/clientes/fasano.png';
import honda from '../../assets/images/clientes/honda.png';
import itaju from '../../assets/images/clientes/itaju.png';
import marisol from '../../assets/images/clientes/marisol.png';
import nissan from '../../assets/images/clientes/nissan.png';
import procergs from '../../assets/images/clientes/procergs.png';
import renault from '../../assets/images/clientes/renault.png';
import telefonica from '../../assets/images/clientes/telefonica.png';
import tj from '../../assets/images/clientes/tj.png';
import uel from '../../assets/images/clientes/uel.png';
import uem from '../../assets/images/clientes/uem.png';
import unimed from '../../assets/images/clientes/unimed.png';
import vivo from '../../assets/images/clientes/vivo.png';
import volvo from '../../assets/images/clientes/volvo.png';

import voip from '../../assets/images/imgs/voip.jpeg';
import mobile from '../../assets/images/imgs/mobile.jpeg';
import informatica from '../../assets/images/imgs/informatica.jpeg';
import servicos from '../../assets/images/imgs/servicos.webp';

async function getTextos() {
  const { data } = await api.get('/textos-site');

  return data;
}

export default function Home() {
  const { data } = useQuery({ queryKey: ['textos'], queryFn: getTextos });
  const history = useHistory();

  const navigate = (categoria) => {
    scrollToTop();
    history.push(`/shop/${categoria}`);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div>
      <div
        id="home"
        className="bg-dark2 h-full w-screen place-items-center px-8 py-28"
        style={{
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${doidera})`,
        }}
      >
        <div className="container mx-auto grid items-center lg:grid-cols-2">
          <div className="text-center lg:text-left">
            {/* <div className="hidden lg:flex">
              <img
                src={logo}
                alt="rsmi"
                width={80}
                className="mb-8 object-fit"
              />
            </div> */}
            <h1 className="block antialiased tracking-normal font-sans text-5xl font-semibold mb-8 mt-8 leading-tight lg:text-6xl">
              {data?.titulo_inicio ||
                'Explore, Descubra e Conecte-se com a RSMI'}
            </h1>
            {/* <p
             className="block antialiased font-sans text-xl font-normal leading-relaxed lg:pr-20">
              {data?.descricao_inicio ||
                `Com inovação e tecnologia a RSMI oferece soluções e serviços
              customizados, proporcionando qualidade e excelência em produtos de
              telecomunicações, viabilizando o retorno do investimento de nossos
              clientes.`}
            </p> */}
            <p
              className="block antialiased font-sans text-xl font-normal leading-relaxed text-white"
              dangerouslySetInnerHTML={{
                __html:
                  data?.descricao_inicio ||
                  `Com inovação e tecnologia a RSMI oferece soluções e serviços
              customizados, proporcionando qualidade e excelência em produtos de
              telecomunicações, viabilizando o retorno do investimento de nossos
              clientes.`,
              }}
            />
          </div>
          <div className="hidden lg:flex">
            <img
              src={banner}
              alt="rsmi"
              className="rounded-3xl ml-auto img-main"
            />
          </div>
        </div>
      </div>
      <section id="solucoes">
        <div className="py-16 px-8 lg:py-28">
          <div className="container mx-auto">
            <div className="mb-24 lg:w-1/2">
              <h2 className="block antialiased tracking-normal font-sans text-4xl font-semibold leading-[1.3] mb-3">
                {data?.titulo_categoria ||
                  'Qualidade e Inovação ao Seu Alcance.'}
              </h2>
              {/* <p className="block antialiased font-sans text-xl font-normal leading-relaxed">
                {data?.descricao_categoria ||
                  `Reunimos as melhores marcas e produtos para o seu negócio, para
              que você possa alcançar o sucesso com qualidade e confiança.`}
              </p> */}

              <p
                className="block antialiased font-sans text-xl font-normal leading-relaxed text-white"
                dangerouslySetInnerHTML={{
                  __html:
                    data?.descricao_categoria ||
                    `Reunimos as melhores marcas e produtos para o seu negócio, para
              que você possa alcançar o sucesso com qualidade e confiança.`,
                }}
              />
            </div>

            <div className="grid gap-8 lg:grid-cols-2 xl:grid-cols-4">
              <button
                onClick={() => navigate('mobile')}
                type="button"
                className="scale-hover touchable shadow-card-img flex-col bg-clip-border rounded-xl text-gray-700 relative grid h-[25rem] w-full max-w-[28rem] justify-center overflow-hidden text-center bg-cover bg-center"
                style={{
                  backgroundImage: `url(${mobile})`,
                }}
              >
                <div className="d-flex items-end w-full h-full">
                  <div className="bg-gradient-to-t w-full h-full" />
                  <div className="p-6 relative py-14 px-6 md:px-12">
                    <h2 className="block antialiased tracking-normal text-2xl leading-snug text-white mb-6 font-medium">
                      Mobile
                    </h2>
                  </div>
                </div>
              </button>

              <button
                onClick={() => navigate('voip')}
                type="button"
                className="scale-hover touchable shadow-card-img flex-col bg-clip-border rounded-xl text-gray-700 relative grid h-[25rem] w-full max-w-[28rem] justify-center overflow-hidden text-center bg-cover bg-center"
                style={{
                  backgroundImage: `url(${voip})`,
                }}
              >
                <div className="d-flex items-end w-full h-full">
                  <div className="bg-gradient-to-t w-full h-full" />
                  <div className="p-6 relative py-14 px-6 md:px-12">
                    <h2 className="block antialiased tracking-normal text-2xl leading-snug text-white font-medium">
                      Comunicação unificada
                    </h2>
                  </div>
                </div>
              </button>

              <button
                onClick={() => navigate('informatica')}
                type="button"
                className="scale-hover touchable shadow-card-img flex-col bg-clip-border rounded-xl text-gray-700 relative grid h-[25rem] w-full max-w-[28rem] justify-center overflow-hidden text-center bg-cover bg-center"
                style={{
                  backgroundImage: `url(${informatica})`,
                }}
              >
                <div className="d-flex items-end w-full h-full">
                  <div className="bg-gradient-to-t w-full h-full" />
                  <div className="p-6 relative py-14 px-6 md:px-12">
                    <h2 className="block antialiased tracking-normal text-2xl leading-snug text-white mb-6 font-medium">
                      Informática
                    </h2>
                  </div>
                </div>
              </button>

              <button
                onClick={() => navigate('servicos')}
                type="button"
                className="scale-hover touchable shadow-card-img flex-col bg-clip-border rounded-xl text-gray-700 relative grid h-[25rem] w-full max-w-[28rem] justify-center overflow-hidden text-center bg-cover bg-center"
                style={{
                  backgroundImage: `url(${servicos})`,
                }}
              >
                <div className="d-flex items-end w-full h-full">
                  <div className="bg-gradient-to-t w-full h-full" />
                  <div className="p-6 relative py-14 px-6 md:px-12">
                    <h2 className="block antialiased tracking-normal text-2xl leading-snug text-white mb-6 font-medium">
                      Serviços
                    </h2>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </section>
      <section
        id="sobre"
        style={{
          backgroundImage: `url(${bannerL})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="py-16 px-8 lg:py-28">
          <div className="container">
            <div className="lg:w-1/2">
              <h2 className="block antialiased tracking-normal font-sans text-4xl font-semibold leading-[1.3]  mb-6">
                Quem somos
              </h2>
              {/* <p className="block antialiased font-sans text-xl font-normal leading-relaxed">
                {data?.descricao_sobrenos ||
                  `Fundada em 2007 na cidade de Curitiba (PR), filiais em Salvador
              (BA), Vitória (ES), Itajaí (SC), Torres (RS), São Miguel do
              Gostoso (RN) e São Paulo (SP), com foco nos mercados corporativo e
              governamental a RSMI é referência nacional no fornecimento de
              produtos de telecomunicações, soluções de UC (Comunicação
              Unificada) e em produtos Mobile (Smartphones, Tablets, Notebooks e
              acessórios, assistência técnica) fornecendo uma solução completa
              para empresas dos mais variados tamanhos. Atua nacionalmente como
              revendedor autorizado de marcas de renome nacional e
              internacional, como Apple, Grandstream, Lenovo, Unify, Audiocodes,
              Samsung, Jabra, Zyxel, Aligera, Felitron, Poly, Nokia, Logitech,
              Khomp, Flyingvoice, Yealink, Intelbras, Fanvil, Alcatel-Lucent e
              Motorola. A RSMI dispõe de um completo portfólio de produtos de
              telefonia IP, além de Headsets, videoconferências, Webcams,
              Smartphones, Tablets, acessórios, Access Point, Switches,
              computadores corporativos. Produtos oficiais, homologados com
              garantia no Brasil, assistência técnica para o mercado
              corporativo, além do fornecimento de suporte e certificação
              técnica para sua empresa.`}
              </p> */}
              <p
                className="block antialiased font-sans text-xl font-normal leading-relaxed text-white"
                dangerouslySetInnerHTML={{
                  __html:
                    data?.descricao_sobrenos ||
                    `Fundada em 2007 na cidade de Curitiba (PR), filiais em Salvador
            (BA), Vitória (ES), Itajaí (SC), Torres (RS), São Miguel do
            Gostoso (RN) e São Paulo (SP), com foco nos mercados corporativo e
            governamental a RSMI é referência nacional no fornecimento de
            produtos de telecomunicações, soluções de UC (Comunicação
            Unificada) e em produtos Mobile (Smartphones, Tablets, Notebooks e
            acessórios, assistência técnica) fornecendo uma solução completa
            para empresas dos mais variados tamanhos. Atua nacionalmente como
            revendedor autorizado de marcas de renome nacional e
            internacional, como Apple, Grandstream, Lenovo, Unify, Audiocodes,
            Samsung, Jabra, Zyxel, Aligera, Felitron, Poly, Nokia, Logitech,
            Khomp, Flyingvoice, Yealink, Intelbras, Fanvil, Alcatel-Lucent e
            Motorola. A RSMI dispõe de um completo portfólio de produtos de
            telefonia IP, além de Headsets, videoconferências, Webcams,
            Smartphones, Tablets, acessórios, Access Point, Switches,
            computadores corporativos. Produtos oficiais, homologados com
            garantia no Brasil, assistência técnica para o mercado
            corporativo, além do fornecimento de suporte e certificação
            técnica para sua empresa.`,
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="py-16 px-8 lg:py-28">
          <div className="container">
            <h2 className="block antialiased tracking-normal font-sans text-4xl font-semibold leading-[1.3]  mb-6">
              Nossos clientes
            </h2>
            <div className="row">
              {[
                acp,
                agepar,
                baldussi,
                barinsul,
                bb,
                brb,
                britania,
                carrier,
                copel,
                directcall,
                fasano,
                honda,
                itaju,
                marisol,
                nissan,
                procergs,
                renault,
                telefonica,
                tj,
                uel,
                uem,
                unimed,
                vivo,
                volvo,
              ].map((m, i) => (
                <div className="col-lg-2 col-md-3 col-sm-4 mb-5" key={i}>
                  <div className="card-cliente w-100 h-100 d-flex align-items-center justify-content-center scale-hover">
                    <img
                      src={m}
                      loading="lazy"
                      alt="cliente"
                      width={122}
                      height={122}
                      className="scale-down"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
