import React, { useRef } from 'react';
import { Row, Col, Card, Button, CardBody, CardFooter } from 'reactstrap';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { Input } from '../../components/unform';
import api from '../../services/api';

function AlterarSenha() {
  const formRef = useRef();

  async function handleSubmit(data) {
    try {
      await api.put('/senha', data);
      toast.success('Senha alterada com sucesso!');
    } catch (error) {
      toast.error('Erro ao mudar senha!');
    }
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Altarar senha</h4>
        </Col>
      </Row>
      <Card>
        <Form
          ref={formRef}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <CardBody>
            <Row>
              <Col lg={6}>
                <Input
                  name="senha"
                  label="Senha antiga"
                  type="password"
                  className="form-control"
                />
              </Col>
              <Col lg={6}>
                <Input
                  name="novaSenha"
                  label="Nova senha"
                  type="password"
                  className="form-control"
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <div className="d-flex justify-content-end mt-3 mb-3">
              <Button className="btn btn-primary" type="submit">
                Salvar
              </Button>
            </div>
          </CardFooter>
        </Form>
      </Card>
    </>
  );
}

export default AlterarSenha;
