import React, { useEffect } from 'react';
import { FaTrash } from 'react-icons/fa';
import api from '../../services/api';
import scrollToTop from '../../utils/scrollToTop';
import { useAuth } from '../../contexts/auth';

function HomeCarrinho() {
  const { delItemCarrinho, carrinho, toggleModal } = useAuth();

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div>
      <div className="container mx-auto rounded-2xl overflow-hidden mt-24">
        <h1 className="block antialiased tracking-normal font-sans text-4xl leading-[1.3] text-white font-normal">
          Carrinho
        </h1>

        <section className="py-8  lg:py-16">
          {carrinho?.length ? (
            <>
              <table className="table bg-dark3">
                <thead>
                  <tr>
                    <th scope="col">Imagem</th>
                    <th scope="col">Produto</th>
                    <th scope="col">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {carrinho.map((m) => (
                    <tr>
                      <td>
                        <div
                          className="position-relative d-flex align-items-center justify-content-center"
                          style={{
                            width: 80,
                            height: 80,
                          }}
                        >
                          <img
                            src={m?.img_url}
                            alt={m.nome}
                            className="product-image__img"
                            crossOrigin="anonymous"
                          />
                        </div>
                      </td>
                      <td>
                        <h5 className="mt-3">{m.nome}</h5>
                      </td>
                      <td>
                        <button
                          onClick={() => delItemCarrinho(m)}
                          className="btn btn-outline-danger mt-3"
                          type="button"
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="3">
                      <div className="d-flex justify-content-end p-3">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={toggleModal}
                        >
                          Finalizar comprar
                        </button>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </>
          ) : (
            <h1 className="block antialiased tracking-normal font-sans text-4xl leading-[1.3] text-white mb-5 font-normal">
              Sem items no carrinho
            </h1>
          )}
        </section>
      </div>
    </div>
  );
}

export default HomeCarrinho;
