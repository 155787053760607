import React, { useEffect, useState } from 'react';
import { FiEdit, FiSettings } from 'react-icons/fi';
import { FaChevronDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { toast } from 'react-toastify';
import api from '../../services/api';
import useValidator from '../../hooks/useValidator';

function Servicos() {
  const { showLoader, closeLoader } = useValidator();

  const [result, setResult] = useState([]);

  const getData = async () => {
    try {
      showLoader();
      const { data } = await api.get('/interno');

      setResult(data);
    } catch (err) {
      toast.error(err.mensagem);
    } finally {
      closeLoader();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Usuários Internos</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {result?.length}</h6>
          <Link
            className="btn btn-primary"
            to="/usuariosinternos/novo"
            color="primary"
          >
            Adicionar
          </Link>
        </div>
        <Table className="rwd-table" responsive>
          <thead>
            <tr>
              <th>Nome</th>
              <th>E-mail</th>
              <th className="text-end">Ação</th>
            </tr>
          </thead>
          <tbody>
            {result?.map((m) => (
              <tr key={m?.id}>
                <td data-label="Nome">{m?.nome}</td>
                <td data-label="E-mail<">{m?.email}</td>
                <td
                  data-label="Action"
                  className="d-flex justify-content-lg-end justify-content-sm-between"
                >
                  <UncontrolledDropdown>
                    <DropdownToggle
                      color="link"
                      className="text-decoration-none justify-between"
                    >
                      <FiSettings className="mr-2" /> <FaChevronDown />
                    </DropdownToggle>
                    <DropdownMenu end container="body">
                      <Link to={`/usuariosinternos/novo?id=${m?.id}`}>
                        <DropdownItem>
                          <FiEdit className="mr-1" />
                          Editar
                        </DropdownItem>
                      </Link>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
    </>
  );
}

export default Servicos;
