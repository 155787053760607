export default function Spinner(props) {
  return (
    <svg
      className="spinner"
      width="20px"
      height="20px"
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
      color="var(--color-secondary)"
      {...props}
    >
      <circle
        className="path"
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
        stroke="currentColor"
      />
    </svg>
  );
}
