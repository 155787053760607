/* eslint-disable no-nested-ternary */
import React, { useRef, useEffect } from 'react';
import Select from 'react-select';
import { useField } from '@unform/core';

const customStyles = {
  multiValue: (base, state) => ({
    ...base,
    backgroundColor: 'rgba(0,0,0,0.1)',
  }),
  option: (styles, { data, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isSelected
      ? 'var(--color-primary) !important'
      : isFocused
      ? 'rgba(var(--color-primary-rgb), 0.1) !important'
      : 'transparent',
    color: '#fff',
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: '#2b2b2b',
  }),
  multiValueLabel: (base, state) => ({ ...base, color: 'white' }),
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: 'transparent',
    borderRadius: 'var(--border-radius)',
    boxShadow: isFocused ? '0 3px 10px 0 rgba(34, 41, 47, 0.1)' : null,
    borderColor: isFocused
      ? 'var(--color-primary) !important'
      : 'rgba(var(--theme-color-rgb), 0.15)',
  }),
};
export default function ReactSelect({ name, label, ...rest }) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
      setValue: (ref, value) => {
        ref.select.setValue(value);
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <div className="form-group">
      <label htmlFor={fieldName} className="form-label">
        {label}
      </label>
      <Select
        defaultValue={defaultValue}
        ref={selectRef}
        classNamePrefix="react-select"
        className={error && 'error'}
        styles={customStyles}
        defaultOptions
        placeholder="Digite para pesquisar"
        noOptionsMessage={() => 'Sem registros'}
        {...rest}
      />
      {error && <small className="error">{error}</small>}
    </div>
  );
}
