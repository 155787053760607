import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import api from '../services/api';
import { Input, Select, TextArea } from '../components/unform';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [tipoPessoa, setPessoa] = useState('juridica');
  const [carrinho, setCarrinho] = useState([]);
  const formRef = useRef();

  const toggleModal = () => setModal((old) => !old);

  const carrinhoCount = carrinho.length;

  const addItemCarrinho = (item) => {
    toast.success(`"${item.nome}" adicionado ao carrinho`);

    if (carrinho.find((f) => f.id === item.id)) {
      return;
    }

    const newCarrinho = carrinho.concat(item);
    setCarrinho(newCarrinho);
    localStorage.setItem('carrinho', JSON.stringify(newCarrinho));
    toggleModal();
  };

  const handleSubmit = async (e) => {
    const errors = {};

    Object.entries(e).forEach(([key, value]) => {
      if (
        !value &&
        !key.includes(['descricao', 'tipo_empresa', 'porte_empresa'])
      ) {
        errors[key] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (Object.keys(errors).length) {
      return toast.error('Verifique os campos em vermelho!');
    }

    try {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await api.post('/cotacao', {
        dados: e,
        produtos: carrinho.map((m) => m.id),
      });
      setCarrinho([]);
      localStorage.removeItem('carrinho');
      Swal.close();
      window.location.href = '/compra-finalizada';
    } catch (err) {
      toast.error(err.msg);
      Swal.close();
    }
  };

  const handleChange = (e) => {
    setTimeout(() => {
      if (e.target.checked) {
        setPessoa(e.target.id);
      }
    }, 100);
  };
  async function signOut() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    setUser(null);
  }

  async function signIn({ senha, email }) {
    try {
      const res = await api.post('/sessions', {
        email,
        senha,
      });

      const { user: usuario, token } = res.data;

      api.defaults.headers.Authorization = `Bearer ${token}`;

      localStorage.setItem(
        'usuario',
        JSON.stringify({
          email,
          nome: usuario.nome,
          perfil: usuario.perfil,
          acessos: usuario.acessos,
        })
      );
      localStorage.setItem('token', token);

      setUser({
        email,
        nome: usuario.nome,
        perfil: usuario.perfil,
        acessos: usuario.acessos,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }
  const delItemCarrinho = (item) => {
    toast.success(`"${item.nome}" removido do carrinho`);
    const newCarrinho = carrinho.filter((f) => f.id !== item.id);
    setCarrinho(newCarrinho);
    localStorage.setItem('carrinho', JSON.stringify(newCarrinho));
  };

  useEffect(() => {
    async function loadStorageData() {
      api.interceptors.response.use(
        (res) => res,
        (error) => {
          const err = error?.response?.data?.error;
          const errMsg = error?.response?.data?.message;
          error.mensagem =
            (typeof err === 'string' && err) ||
            (typeof errMsg === 'string' && errMsg) ||
            'Ocorreu algum problema, tente novamente mais tarde!';

          error.titulo = 'Ops...';

          if (error?.response?.status === 401) {
            signOut();
          }

          return Promise.reject(error);
        }
      );

      const { usuario: storagedUser, token: storagedToken } = localStorage;

      if (storagedUser && storagedToken) {
        setUser(JSON.parse(storagedUser));
        api.defaults.headers.Authorization = `Bearer ${storagedToken}`;
      }

      setLoading(false);
    }
    try {
      const strCart = localStorage.getItem('carrinho');
      if (strCart) {
        const cart = JSON.parse(strCart);
        if (Array.isArray(cart) && cart.length) {
          setCarrinho(cart);
        }
      }
    } catch (err) {
      setCarrinho([]);
    }
    loadStorageData();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        addItemCarrinho,
        delItemCarrinho,
        toggleModal,
        carrinho,
        carrinhoCount,
        loading,
        signIn,
        signOut,
      }}
    >
      {children}
      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Informe seus dados</ModalHeader>
        <Form
          ref={formRef}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <ModalBody>
            <div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="tipoPessoa"
                  id="juridica"
                  defaultChecked
                  onFocus={(e) => handleChange(e)}
                />
                <label className="form-check-label ml-1" htmlFor="juridica">
                  Pessoa Jurídica
                </label>
              </div>
              {/* <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="tipoPessoa"
                  id="fisica"
                  onFocus={(e) => handleChange(e)}
                />
                <label className="form-check-label ml-1" htmlFor="fisica">
                  Pessoa Física
                </label>
              </div> */}
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Input
                  name="nome"
                  label="Nome"
                  className="form-control"
                  placeholder="Informe seu nome"
                />
              </div>
              {tipoPessoa === 'fisica' ? (
                <>
                  <div className="col-lg-6">
                    <Input
                      name="cpf"
                      label="CPF"
                      className="form-control"
                      placeholder="CPF"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-lg-6">
                    <Input
                      name="cnpj"
                      label="CNPJ"
                      className="form-control"
                      placeholder="CNPJ"
                    />
                  </div>
                </>
              )}
              <div className="col-lg-6">
                <Input
                  name="email"
                  label="Email"
                  className="form-control"
                  placeholder="Informe seu email"
                />
              </div>
              <div className="col-lg-6">
                <Input
                  name="telefone"
                  label="Telefone"
                  className="form-control"
                  placeholder="(DDD) 90000-0000"
                />
              </div>
              <div className="col-lg-6">
                <Select
                  name="tipo_empresa"
                  label="Tipo empresa"
                  className="form-control"
                  data={[
                    {
                      label: 'Selecione',
                      value: '',
                    },
                    {
                      label: 'Indústria',
                      value: 'Indústria',
                    },
                    {
                      label: 'Comércio',
                      value: 'Comércio',
                    },
                    {
                      label: 'Serviço',
                      value: 'Serviço',
                    },
                  ]}
                />
              </div>
              <div className="col-lg-6">
                <Select
                  name="porte_empresa"
                  label="Porte da empresa"
                  className="form-control"
                  data={[
                    {
                      label: 'Selecione',
                      value: '',
                    },
                    {
                      label: 'Pequeno',
                      value: 'Pequeno',
                    },
                    {
                      label: 'Médio',
                      value: 'Médio',
                    },
                    {
                      label: 'Grande',
                      value: 'Grande',
                    },
                  ]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <TextArea
                  name="descricao"
                  label="Descrição (opcional)"
                  className="form-control"
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="justify-content-between">
            <button
              type="button"
              className="btn btn-primary-outline btn-round"
              onClick={toggleModal}
            >
              Continuar comprando
            </button>
            <button type="submit" className="btn btn-primary btn-round">
              Enviar dados para compra
            </button>
          </ModalFooter>
        </Form>
      </Modal>
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider.');
  }

  return context;
}

export { AuthProvider, useAuth };
