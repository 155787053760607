import React from 'react';
import logo from '../../assets/images/logo.png';

function HomeCompraFinalizada() {
  return (
    <div>
      <div className="container mx-auto rounded-2xl overflow-hidden mt-24">
        <h1 className="block antialiased tracking-normal font-sans text-4xl leading-[1.3] text-center text-white font-normal">
          Obrigado pela escolha!
        </h1>

        <section className="py-8  lg:py-16">
          <div className="d-flex ">
            <img
              src={logo}
              alt={logo}
              className="object-fit"
              height={150}
              width={150}
            />
            <div className="ml-3 mt-5">
              <h3 className="text-center">
                Em breve um dos nossos especialistas entrará em contato. Conte
                conosco para receber soluções personalizadas e qualidade
                excepcional.
              </h3>
              <h4 className="text-center gray-1">
                Nosso atendimento é de segunda a sexta-feira, das 9h às 18h.
              </h4>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default HomeCompraFinalizada;
