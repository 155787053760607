/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  FaBars,
  FaEnvelope,
  FaFacebookSquare,
  FaInstagramSquare,
  FaLinkedin,
  FaPhone,
  FaShoppingCart,
  FaWhatsapp,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import logo from '../assets/images/logo-sm.png';
import { useAuth } from '../contexts/auth';

export default function LayoutHome({ children }) {
  const [isHidden, setIsHidden] = useState(true);
  const [showDropDown, setShowDropDown] = useState(false);
  const { carrinhoCount } = useAuth();

  return (
    <div className={'m-0 bg-gray home'.concat(isHidden ? '' : ' block-scroll')}>
      <header>
        <div className="container d-flex justify-content-between">
          <Link to="/">
            <img
              src={logo}
              width={50}
              height={50}
              className="object-fit"
              alt="logo rsmi "
            />
          </Link>
          <div className="fc-toolbar-chunk justify-content-end md:hidden">
            <Link to="/carrinho" className="touchable">
              {carrinhoCount > 0 && (
                <div className="relative">
                  <span className="badge-carrinho">{carrinhoCount}</span>
                </div>
              )}
              <div className="p-3">
                <FaShoppingCart size={24} color="#fff" />
              </div>
            </Link>
            <button
              type="button"
              className="touchable ml-3"
              onClick={() => setIsHidden((old) => !old)}
            >
              <div className="p-3">
                <FaBars size={24} color="#fff" />
              </div>
            </button>
          </div>

          <div className="hidden md:flex align-items-center">
            <a href="/#home" className="ml-3 mr-3 text-white">
              Home
            </a>
            {/* <Link to="/" className="ml-3 mr-3 text-white">
              Home
            </Link> */}
            <a href="/#solucoes" className="mx-4 text-white">
              Soluções
            </a>
            <a href="/#sobre" className="mx-4 ml-3 text-white">
              Quem somos
            </a>
            {/* <a href="/contato" className="mx-4 text-white">
              Contato
            </a> */}
            <Link to="/contato" className="ml-3 mr-3 text-white">
              Contato
            </Link>
            <div
              className="dropdown ml-3 mr-3"
              onMouseOver={() => {
                if (!showDropDown) {
                  setShowDropDown(true);
                }
              }}
              onFocus={() => {
                //
              }}
              onMouseLeave={() => {
                setShowDropDown(false);
              }}
            >
              <button
                className="btn-none color-white dropdown-toggle"
                type="button"
                id="dropdownMenu2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Conteúdos &nbsp;
              </button>
              <div
                className="dropdown-menu"
                style={{
                  display: showDropDown ? 'block' : 'none',
                }}
              >
                <Link className="dropdown-item" to="/blog">
                  Blog
                </Link>
                <Link className="dropdown-item" to="/noticias">
                  Notícias
                </Link>
              </div>
            </div>
            <a href="https://rsmi.business" className="mx-4 text-white">
              Apple
            </a>
            <a href="tel:+554135277898" className="mx-4 text-white">
              (41) 3527-7898
            </a>
            <Link to="/carrinho" className="touchable ml-3">
              {carrinhoCount > 0 && (
                <div className="relative">
                  <span className="badge-carrinho">{carrinhoCount}</span>
                </div>
              )}
              <div className="p-3 d-flex align-items-center">
                <p className="mb-0 text-white mr-3">Carrinho</p>
                <FaShoppingCart size={24} color="#fff" />
              </div>
            </Link>
          </div>
          <div className="hidden md:flex" />
        </div>
      </header>
      <div
        className="navmenu touchable"
        hidden={isHidden}
        onClick={() => {
          setIsHidden(true);
        }}
      >
        <div>
          {/* <Link to="/" className="ml-3 mr-3 text-white">
            Home
          </Link> */}
          <a href="/#home" className="ml-3 mr-3 text-white">
            Home
          </a>
        </div>
        <div>
          <a href="/#solucoes" className="ml-3 mr-3 text-white">
            Soluções
          </a>
        </div>
        <div>
          <a href="/#sobre" className="ml-3 mr-3 text-white">
            Quem somos
          </a>
        </div>
        <div>
          {/* <a href="/#contato" className="ml-3 mr-3 text-white">
            Contato
          </a> */}
          <Link to="/contato" className="ml-3 mr-3 text-white">
            Contato
          </Link>
        </div>
        <div>
          <Link className="ml-3 mr-3 text-white" to="/blog">
            Blog
          </Link>
        </div>
        <div>
          <Link className="ml-3 mr-3 text-white" to="/noticias">
            Notícias
          </Link>
        </div>
        <div>
          <a href="https://rsmi.business" className="ml-3 mr-3 text-white">
            Apple
          </a>
        </div>
        <div>
          <a href="tel:+554135277898" className="ml-3 text-white">
            (41) 3527-7898
          </a>
        </div>
      </div>
      <main className="pt-70px">
        {children}
        <div className="mt-16" />
      </main>
      <footer>
        <div className="container">
          <div className="lg:flex align-items-center justify-content-between pt-8">
            <div className="d-flex justify-content-center pb-3">
              <img
                src={logo}
                width={50}
                className="object-fit"
                alt="logo rsmi"
              />
            </div>
            <div className="text-center pb-3" id="contato">
              <a
                href="https://www.google.com/maps/place/Grandstream+Brasil+-+RSMI+Representante+Autorizado/@-25.4119042,-49.2606229,17z/data=!4m6!3m5!1s0x94dce4241bc68e71:0x7b206d8aae2b6f1a!8m2!3d-25.4119091!4d-49.258048!16s%2Fg%2F11cjhzcmfg?entry=ttu"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: '#FAFAFF',
                }}
              >
                Avenida João Gualberto, 1881 - Conjunto 1802 - Juvevê -
                Curitiba(PR) - CEP 80030-001
              </a>
              <p className="mt-1 gray-1">
                Horário de Funcionamento: Segunda a sexta, das 9h às 18h
              </p>
            </div>
            <div className="d-flex pb-3 justify-content-center">
              <a
                href="https://www.facebook.com/rsmicuritiba"
                target="_blank"
                rel="noreferrer"
                aria-label="Facebook"
              >
                <FaFacebookSquare color="#FAFAFF" />
              </a>
              <a
                href="https://www.instagram.com/rsmi_distribuidora/"
                target="_blank"
                className="px-8"
                rel="noreferrer"
                aria-label="Instagram"
              >
                <FaInstagramSquare color="#FAFAFF" />
              </a>
              <a
                href="https://www.linkedin.com/company/rsmi-distribuidora/"
                target="_blank"
                rel="noreferrer"
                aria-label="Linkedin"
              >
                <FaLinkedin color="#FAFAFF" />
              </a>
            </div>
          </div>
          <div className="lg:flex grid between align-items-center pb-3 mt-3">
            <div className="text-center mt-3">
              <p className="mb-3 gray-1">
                Made with ❤️ by{' '}
                <a
                  aria-label="xcoding"
                  href="https://xcoding.com.br?ref=rsmi.com.br"
                  style={{ color: '#fff' }}
                >
                  Xcoding
                </a>{' '}
              </p>
            </div>

            <div className="lg:flex">
              <a
                href="mailto:comercial@rsmi.com.br"
                target="_blank"
                rel="noreferrer"
                aria-label="comercial@rsmi.com.br"
                className="d-flex justify-content-center align-items-center mb-3 mr-3"
              >
                <FaEnvelope color="#DADDD8" className="mr-3" />
                <p className="gray-1 mb-0">comercial@rsmi.com.br</p>
              </a>
              <a
                href="tel:+554135277898"
                target="_blank"
                rel="noreferrer"
                aria-label="+55 (41) 3527-7898"
                className="d-flex justify-content-center align-items-center mb-3 ml-3"
              >
                <FaPhone color="#DADDD8" className="mr-3" />
                <p className="gray-1 mb-0">+55 (41) 3527-7898</p>
              </a>
            </div>
          </div>
        </div>
      </footer>
      <a
        href="https://api.whatsapp.com/send/?phone=554135277898&text=Ol%C3%A1%2C%20estou%20no%20site%20da%20RSMI%20e%20gostaria%20de%20uma%20cota%C3%A7%C3%A3o.&app_absent=0"
        className="float"
        aria-label="whatsapp"
        target="_blank"
        rel="noreferrer"
      >
        <FaWhatsapp color="#fff" />
      </a>
    </div>
  );
}
