/* eslint-disable no-nested-ternary */
import { useQuery } from '@tanstack/react-query';
import { useParams, Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import api from '../../services/api';
import scrollToTop from '../../utils/scrollToTop';

import voip from '../../assets/images/imgs/voip.jpeg';
import mobile from '../../assets/images/imgs/mobile.jpeg';
import informatica from '../../assets/images/imgs/informatica.jpeg';
import servicos from '../../assets/images/imgs/servicos.webp';

const titles = {
  voip: 'Comunicação unificada',
  mobile: 'Mobile',
  informatica: 'Informática',
  servicos: 'Serviços',
};

const fotos = {
  voip,
  mobile,
  informatica,
  servicos,
};

async function getMarcas(categoria = '') {
  if (categoria === 'servicos') {
    const { data } = await api.get(`/produtoservico?categoria=${categoria}`);

    return data;
  }

  const { data } = await api.get(`/marca?categoria=${categoria}`);

  return data;
}

async function getTexto(categoria = '') {
  const { data } = await api.get(`/textos-categorias?categoria=${categoria}`);

  return data;
}

function HomeMarcas() {
  const params = useParams();
  const { data } = useQuery({
    queryKey: [params?.categoria || 'marca'],
    queryFn: () => getMarcas(params?.categoria),
  });

  const { data: textos } = useQuery({
    queryKey: [`textos:categorias:${params?.categoria}`],
    queryFn: () => getTexto(params?.categoria),
  });

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div>
      <div className="container mx-auto rounded-2xl overflow-hidden mt-24">
        <div className="relative flex bg-clip-border flex-col-reverse overflow-hidden rounded-3xl md:flex-row md:gap-8 bg-dark2">
          <div className="p-6 md:py-20 md:px-14 md:w-7/12">
            <h1 className="block antialiased tracking-normal font-sans text-4xl leading-[1.3] text-white mb-5 font-normal">
              {titles[params?.categoria] || 'Marcas'}
            </h1>
            <p
              className="block antialiased font-sans text-lg font-normal leading-relaxed text-white"
              dangerouslySetInnerHTML={{
                __html: textos?.descricao,
              }}
            />
          </div>
          <div className="relative grid h-100 place-items-center md:h-auto md:w-5/12">
            <img
              src={fotos[params?.categoria] || fotos.servicos}
              className="h-full w-full object-cover md:absolute"
              alt={titles[params?.categoria] || 'foto'}
            />
          </div>
        </div>

        <section className="py-8  mt-16 lg:py-16">
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {!data?.items &&
              [1, 2, 3, 4].map((m) => (
                <div
                  className="animated-background mt-4 mx-4 rounded-xl overflow-hidden !m-0 !mb-6 h-64 p-3"
                  key={m}
                />
              ))}
            {data?.items?.map((m, i) =>
              String(m.nome).toLocaleLowerCase().includes('apple') ? (
                <a
                  href="https://rsmi.business?ref=rsmi.com.br"
                  target="_blank"
                  key={m?.nome || i}
                  className="relative scale-hover bg-clip-border bg-white mt-4 mx-4 rounded-xl overflow-hidden !m-0 !mb-6 h-64 p-3"
                  rel="noreferrer"
                >
                  <img
                    src={m?.img_url}
                    alt={m?.nome || 'marca'}
                    className="h-full w-full object-fit object-center"
                  />
                </a>
              ) : params?.categoria === 'servicos' ? (
                <div
                  key={m?.nome || i}
                  className="h-full w-full bg-white scale-hover rounded-xl mt-4 mx-4 overflow-hidden !m-0 !mb-6 h-70"
                >
                  <p className="text-black block p-3 mb-0 antialiased font-sans text-xl font-normal leading-relaxed">
                    {m?.nome}
                  </p>
                  <Link
                    to={`/produto/${m.id}-${m.nome}`}
                    className="relative bg-clip-border h-64"
                  >
                    <img
                      src={m?.img_url}
                      alt={m?.nome || 'marca'}
                      className="w-full object-fit object-center"
                    />
                  </Link>
                  {/* <div className="d-flex justify-content-between p-3">
                    <Link
                      className="btn btn-primary-outline btn-round font-semibold"
                      to={`/produto/${m.id}-${m.nome}`}
                    >
                      + Detalhes
                    </Link>
                    <button
                      className="btn btn-primary-light btn-round font-semibold"
                      type="button"
                      onClick={() => {
                        addItemCarrinho({ ...m, descricao: null });
                      }}
                    >
                      Comprar
                    </button>
                  </div> */}
                </div>
              ) : (
                <Link
                  to={`/shop/${params?.categoria}/${m.id}-${m.nome}`}
                  key={m?.nome || i}
                  className="relative scale-hover bg-clip-border bg-white mt-4 mx-4 rounded-xl overflow-hidden !m-0 !mb-6 h-64 p-3"
                >
                  <img
                    src={m?.img_url}
                    alt={m?.nome || 'marca'}
                    className="h-full w-full object-fit object-center"
                  />
                </Link>
              )
            )}
          </div>
        </section>
      </div>
    </div>
  );
}

export default HomeMarcas;
