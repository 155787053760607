import React, { useRef } from 'react';
import { FiEdit, FiFilter, FiSettings, FiTrash } from 'react-icons/fi';
import { FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from 'reactstrap';
import { Form } from '@unform/web';
import { Input } from '../../components/unform/index';
import api from '../../services/api';

function Dashboard() {
  let debounce = null;
  const filtrosRef = useRef();

  function filtrarPesquisa() {
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      filtrosRef.current.submitData();
    }, 500);
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Dasboard</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {0}</h6>
          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter color="rgba(var(--theme-color-rgb), 0.6)" size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <Link className="btn btn-primary" to="/criar" color="primary">
            Adicionar
          </Link>
        </div>
        <div className="filter-collapse px-3 pb-3">
          <UncontrolledCollapse toggler="#toggler">
            <Form onSubmit={(data) => console.log(data)} ref={filtrosRef}>
              <Row>
                <Col>
                  <Input
                    className="form-control"
                    placeholder="nome"
                    name="nome"
                    onChange={filtrarPesquisa}
                  />
                </Col>
                <Col>
                  <Input
                    className="form-control"
                    placeholder="documento"
                    name="documento"
                    onChange={filtrarPesquisa}
                  />
                </Col>
                <Col>
                  <Input
                    className="form-control"
                    placeholder="email"
                    name="email"
                    onChange={filtrarPesquisa}
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div>

        <Table className="rwd-table" responsive>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Username</th>
              <th className="text-end">Action</th>
            </tr>
          </thead>
          <tbody>
            {Array.from(Array(5).keys()).map((m) => (
              <tr key={m}>
                <td data-label="First Name">Mark</td>
                <td data-label="Last Name">Otto</td>
                <td data-label="Username">@mdo</td>
                <td
                  data-label="Action"
                  className="d-flex justify-content-lg-end justify-content-sm-between"
                >
                  <UncontrolledDropdown>
                    <DropdownToggle
                      color="link"
                      className="text-decoration-none padding-0-5"
                    >
                      <FiSettings className="mr-2" /> <FaChevronDown />
                    </DropdownToggle>
                    <DropdownMenu end>
                      <DropdownItem>
                        <FiEdit className="mr-1" />
                        Editar
                      </DropdownItem>
                      <DropdownItem>
                        <FiTrash className="mr-1" />
                        Excluir
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={Number(5) || 1}
          // onPageChange={({ selected }) => setPage(selected)}
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
    </>
  );
}

export default Dashboard;
