import { useQuery } from '@tanstack/react-query';
import { useParams, Link } from 'react-router-dom';
import React, { useEffect, useMemo } from 'react';
import api from '../../services/api';
import scrollToTop from '../../utils/scrollToTop';

async function getSubCategoria(marca, categoria) {
  const { data } = await api.get(
    `/subcategorias-marca/${marca}?id_categoria=${categoria}`
  );

  return data;
}

async function getMarca(marca) {
  const { data } = await api.get(`/marca/${marca}`);
  return data;
}

function HomeSubCategoria() {
  const params = useParams();
  const marca = useMemo(() => {
    const arr = String(params?.marca).split('-');

    const id = arr.shift();
    return {
      id,
      nome: arr.join('-'),
    };
  }, [params?.marca]);

  const { data: dadosMarca } = useQuery({
    queryKey: [`marca:${marca.id}`],
    queryFn: () => getMarca(marca.id),
  });

  const { data } = useQuery({
    queryKey: [`marca:${marca.id}:${params?.categoria}:subcategorias`],
    queryFn: () => getSubCategoria(marca.id, params?.categoria),
  });

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div>
      <div className="container mx-auto rounded-2xl overflow-hidden mt-24">
        <section>
          <h1 className="block antialiased tracking-normal font-sans leading-[1.3] font-normal">
            {dadosMarca?.nome || marca.nome}
          </h1>
        </section>

        <section className="py-8 lg:py-16">
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {!data &&
              [1, 2, 3, 4].map((m) => (
                <div
                  className="animated-background p-3 overflow-hidden rounded-xl"
                  key={m}
                >
                  <div className="relative bg-clip-border  mt-4 mx-4 rounded-xl overflow-hidden !m-0 !mb-6 h-64 p-3" />
                  <p className="text-white block mt-4 mb-3 antialiased font-sans text-xl font-normal leading-relaxed">
                    &nbsp;
                  </p>
                </div>
              ))}
            {data?.map((m, i) => (
              <Link
                to={`/shop/${params?.categoria}/${marca.id}-${marca.nome}/${m.Subcategoria.id}-${m.Subcategoria.nome}`}
                key={m?.nome || i}
                className="relative scale-hover bg-clip-border bg-white mt-4 mx-4 rounded-xl overflow-hidden !m-0 !mb-6 p-3"
              >
                <p className="text-black block antialiased font-sans text-xl font-normal leading-relaxed">
                  {m?.Subcategoria.nome}
                </p>
                <div className="h-64">
                  <img
                    src={m?.Subcategoria?.img_url}
                    alt={m?.Subcategoria.nome || 'subcategoria'}
                    className="h-full w-full object-fit object-center"
                  />
                </div>
              </Link>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}

export default HomeSubCategoria;
