import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser } from 'react-icons/fi';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  CardTitle,
  CardText,
  Card,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Placeholder,
} from 'reactstrap';

import { Input, Select, InputGroup, TextArea } from '../../components/unform';

function Dashboard() {
  const formRef = useRef();
  const [activeTab, setActiveTab] = useState('1');
  const [modal, setModal] = useState(false);
  const [offcanvas, setOffcanvas] = useState(false);

  const toggleModal = () => setModal(!modal);
  const toggleOffcanvas = () => setOffcanvas(!offcanvas);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Components</h4>
        </Col>
      </Row>
      <Card className="px-3 py-3">
        <Form
          ref={formRef}
          onSubmit={(e) => {
            console.log(e);
          }}
        >
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === '1' ? 'active' : null}
                onClick={() => {
                  toggle('1');
                }}
              >
                Form
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === '2' ? 'active' : null}
                onClick={() => {
                  toggle('2');
                }}
              >
                Components
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col lg={2} md={3} sm={6}>
                  <Input name="input" label="Input" className="form-control" />
                </Col>
                <Col lg={2} md={3} sm={6}>
                  <InputGroup
                    name="input-group"
                    label="Input group"
                    className="form-control-group"
                    prepend={<FiMail />}
                    append={<FiUser />}
                  />
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <Select
                    name="select"
                    label="Select"
                    className="form-control"
                    data={[
                      { label: 'Selecione' },
                      { label: 'item 1', value: '1' },
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Input
                    label="Choose a color"
                    className="form-control"
                    name="color"
                    type="color"
                  />
                </Col>
                <Col lg={3}>
                  <Input
                    label="Choose a number"
                    className="form-control"
                    name="number"
                    type="number"
                  />
                </Col>
                <Col lg={3}>
                  <Input
                    label="Month"
                    name="month"
                    className="form-control"
                    type="month"
                    min="2020-09"
                  />
                </Col>
                <Col lg={3}>
                  <Input
                    label="time"
                    className="form-control"
                    name="time"
                    type="time"
                    min="09:00"
                    max="18:00"
                  />
                </Col>
                <Col lg={3}>
                  <Input
                    className="form-control"
                    label="week"
                    min="2021-W01"
                    max="2021-W52"
                    name="week"
                    type="week"
                  />
                </Col>
                <Col lg={3}>
                  <Input
                    className="form-control"
                    label="date"
                    min="2021-01-01"
                    max="2021-12-31"
                    name="date"
                    type="date"
                  />
                </Col>
                <Col lg={3}>
                  <Input
                    className="form-control"
                    label="meeting-time"
                    min="2020-06-07T00:00"
                    max="2020-06-14T00:00"
                    name="meeting"
                    type="datetime-local"
                  />
                </Col>
                <Col lg={3}>
                  <Input
                    className="form-control"
                    label="search"
                    aria-label="Search through site content"
                    name="search"
                    type="search"
                  />
                </Col>
                <Col lg={3}>
                  <Input
                    type="range"
                    name="volume"
                    label="Volume"
                    min="0"
                    max="10"
                  />
                </Col>
                <Col lg={3}>
                  <Input type="file" name="file" label="file" />
                </Col>
                <Col lg={6}>
                  <TextArea
                    name="input"
                    label="Input"
                    className="form-control"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button color="primary btn-block" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="6">
                  <Card body>
                    <CardTitle>Modal</CardTitle>
                    <CardText>
                      With supporting text below as a natural lead-in to
                      additional content.
                    </CardText>
                    <Button type="button" color="success" onClick={toggleModal}>
                      Abrir modal
                    </Button>
                  </Card>
                </Col>
                <Col sm="6">
                  <Card body>
                    <CardTitle>Offcanvas</CardTitle>
                    <CardText>
                      With supporting text below as a natural lead-in to
                      additional content.
                    </CardText>
                    <Button
                      type="button"
                      color="danger"
                      onClick={toggleOffcanvas}
                    >
                      Abrir offcanvas
                    </Button>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Form>
      </Card>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Modal title</ModalHeader>
        <ModalBody>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal}>
            Do Something
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Offcanvas isOpen={offcanvas} toggle={toggleOffcanvas}>
        <OffcanvasHeader toggle={toggleOffcanvas}>Offcanvas</OffcanvasHeader>
        <OffcanvasBody>
          <strong>This is the Offcanvas body.</strong>
          <Placeholder animation="wave">
            <Placeholder xs={8} color="light-secondary" />
          </Placeholder>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
}

export default Dashboard;
