import { useRef, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
// import Logo from '../../assets/logo-text2.png';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Input, InputGroup } from '../../components/unform/index';
import { Spinner } from '../../components/index';
import { useAuth } from '../../contexts/auth';
import login from '../../assets/images/logo.png';

export default function Login() {
  const { signIn } = useAuth();
  const history = useHistory();
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    const errors = {};

    Object.keys(e).forEach((f) => {
      if (!e[f]) {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      setLoading(true);
      try {
        await signIn(e);
        history.push('/');
      } catch (err) {
        toast.error('Erro ao realizar login!');
      }
      setLoading(false);
    }
  };
  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <Row className="vh-100 m-0 bg-gray">
      <Col
        className="h-100 d-none d-lg-flex align-items-center p-5"
        lg={8}
        sm={12}
      >
        <div className="d-lg-flex align-items-center justify-content-center px-5">
          <img className="img-login" src={login} alt="Login V2" />
        </div>
      </Col>
      <Col
        className="d-flex align-items-center auth-bg px-2 p-lg-5 bg-white-theme"
        lg={4}
        sm={12}
      >
        <Col className="px-xl-4 mx-auto" sm={8} md={6} lg={12}>
          <h3 className="font-weight-bold mb-1 card-title">Acessar painel</h3>
          <p className="mb-4 card-text-login">
            Por favor faça o login para prosseguir
          </p>

          <Form onSubmit={(data) => handleSubmit(data)} ref={formRef}>
            <Input
              name="email"
              label="Email"
              className="form-control"
              placeholder="Seu email"
              defaultValue={process.env.REACT_APP_EMAIL}
            />
            <InputGroup
              name="senha"
              label="Senha"
              type={showPassword ? 'text' : 'password'}
              className="form-control-group"
              placeholder="* * * * *"
              defaultValue={process.env.REACT_APP_SENHA}
              append={
                <Button size="sm" color="link" onClick={toggleShowPassword}>
                  {showPassword ? <FiEyeOff size={16} /> : <FiEye size={16} />}
                </Button>
              }
            />

            <Button
              color="primary"
              className="btn-block mt-2 mb-2"
              disabled={loading}
            >
              {loading ? <Spinner /> : 'Login'}
            </Button>
            <hr />
            <div className="d-flex justify-content-center">
              {/* <Link to="/esqueci-minha-senha" className="pt-1">
                Esqueceu a senha ?
              </Link> */}
            </div>
          </Form>
        </Col>
      </Col>
    </Row>
  );
}
