import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Row, Col, Card } from 'reactstrap';
import api from '../../services/api';

function Conteudo() {
  const [dados, setDados] = useState();
  const params = useParams();

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await api.get(`/conteudos/${params.slug}/slug`);
        setDados(data);
      } catch (err) {
        toast.error(err.msg);
      }
    };
    getData();
  }, [params]);

  return (
    <>
      <div className="container">
        <Row className="justify-content-center">
          <Col lg={10}>
            <h1 className="text-header my-3 mb-0 pb-0">
              <strong>{dados?.titulo}</strong>
            </h1>
            <small className="text-muted ">
              {new Date(dados?.createdAt).toLocaleDateString('pt-BR')}
            </small>
            <div className="mt-3 d-flex">
              <img
                className="object-fit"
                width="100%"
                src={dados?.img_url}
                alt={dados?.titulo}
              />
            </div>

            <article
              className="mt-5 ql-editor"
              style={{
                height: 'auto',
                minHeight: '10px',
              }}
              dangerouslySetInnerHTML={{
                __html: dados?.descricao,
              }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Conteudo;
