import React, { useEffect } from 'react';
import { FaEnvelope, FaPhone, FaGlobeAmericas } from 'react-icons/fa';
import { useQuery } from '@tanstack/react-query';
import scrollToTop from '../../utils/scrollToTop';

import contato from '../../assets/images/imgs/contato.jpeg';
import api from '../../services/api';

async function getTextos() {
  const { data } = await api.get('/textos-site');

  return data;
}

function removerCaracteresEspeciais(texto) {
  if (!texto) {
    return '';
  }
  return texto.replace(/[^0-9]/g, '');
}

function HomeProdutoDetalhe() {
  const { data } = useQuery({ queryKey: ['textos'], queryFn: getTextos });

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div>
      <div className="container overflow-hidden mt-24 mb-24">
        <h1 className="block antialiased tracking-normal font-sans text-4xl leading-[1.3] text-white font-normal">
          Contato
        </h1>
        <div className="row mt-5">
          <div className="col-12 col-lg-6">
            <img
              className="img-produto rounded-2xl w-90"
              alt="foto produto"
              src={contato}
            />
          </div>
          <div className="col-12 col-lg-6">
            <h3 className="text-center">TELEFONE / WHATSAPP</h3>
            <br />
            <a
              href={`https://api.whatsapp.com/send/?phone=${removerCaracteresEspeciais(
                data?.contato_telefone
              )}&text&app_absent=0`}
              target="_blank"
              rel="noreferrer"
              aria-label={data?.contato_telefone}
              className="d-flex justify-content-center align-items-center mb-3 ml-3"
            >
              <FaPhone color="#DADDD8" className="mr-3" />
              <p className="gray-1 mb-0">{data?.contato_telefone}</p>
            </a>
            <br />
            <h3 className="text-center">E-MAIL</h3>
            <br />
            <a
              href={`mailto:${data?.contato_telefone}`}
              target="_blank"
              rel="noreferrer"
              aria-label={data?.contato_telefone}
              className="d-flex justify-content-center align-items-center mb-3 mr-3"
            >
              <FaEnvelope color="#DADDD8" className="mr-3" />
              <p className="gray-1 mb-0">{data?.contato_email}</p>
            </a>
            <br />
            <h3 className="text-center">WEBSITES</h3>
            <br />

            <a
              rel="noreferrer"
              target="_blank"
              href={data?.contato_website_1}
              className="d-flex justify-content-center align-items-center mb-3 mr-3"
            >
              <FaGlobeAmericas color="#DADDD8" className="mr-3" />
              <p className="gray-1 mb-0">{data?.contato_website_1}</p>
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href={data?.contato_website_2}
              className="d-flex justify-content-center align-items-center mb-3 mr-3"
            >
              <FaGlobeAmericas color="#DADDD8" className="mr-3" />
              <p className="gray-1 mb-0"> {data?.contato_website_2}</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeProdutoDetalhe;
