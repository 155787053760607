import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import Swal from 'sweetalert2';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Card, Button, CardBody } from 'reactstrap';
import { Input, Select, Image, Editor } from '../../components/unform';
import api from '../../services/api';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn button btn-success',
    cancelButton: 'button btn btn-danger',
  },
  buttonsStyling: false,
});

function NovoConteudo() {
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const formRef = useRef();
  const history = useHistory();
  const location = useLocation();

  async function handleSubmit(data) {
    const errors = {};
    const query = {};

    location.search
      .substring(1)
      .split('&')
      .forEach((f) => {
        const [key, value] = f.split('=');
        if (value) {
          query[key] = value;
        }
      });

    Object.entries(data).forEach(([key, value]) => {
      if (!value && !(key === 'file' && query.id)) {
        errors[key] = 'Obrigatório';
      }
    });
    if (Object.keys(errors).length) {
      formRef.current.setErrors(errors);

      return toast.error('Verifique os campos obrigatórios');
    }

    if (loading) {
      return;
    }
    try {
      formRef.current.setErrors({});
      setLoading(true);
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      if (query.id) {
        await api.put(`/conteudos/${query.id}`, formData);
        toast.success('Conteúdo alterado com sucesso!');
      } else {
        await api.post('/conteudos', formData);
        toast.success('Conteúdo criado sucesso!');
      }

      setLoading(false);
      Swal.close();
      history.push('/conteudos');
    } catch (err) {
      if (err.response?.data?.errors) {
        formRef.current.setErrors(err.response?.data?.errors);
      }
      Swal.close();
      setLoading(false);
      toast.error(err.msg);
    }
  }

  async function deleteConteudo() {
    if (!update) {
      return;
    }
    try {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await api.delete(`/conteudos/${update}`);
      Swal.close();
      toast.warn('Conteúdo excluído com sucesso!');
      history.push('/conteudos');
    } catch (err) {
      Swal.close();
      toast.error(err.msg);
    }
  }

  async function handleDelete() {
    swalWithBootstrapButtons
      .fire({
        title: 'Tem certeza que deseja excluir este conteúdo ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
        html: 'Esta operação não poderá ser revertida',
      })
      .then(async (result) => {
        if (result.value) {
          await deleteConteudo();
        }
      });
  }

  useEffect(() => {
    async function getData(id) {
      try {
        Swal.fire({
          title: 'Aguarde',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        const { data } = await api.get(`/conteudos/${id}`);
        document.querySelector('textarea').style.height = '500px';
        formRef.current.setData({
          ...data,
          file: data.img_url,
        });
        Swal.close();
      } catch (err) {
        Swal.close();
        toast.error('Ocorreu um erro tente novamente mais tarde');
        history.goBack();
      }
    }
    const query = {};

    location.search
      .substring(1)
      .split('&')
      .forEach((f) => {
        const [key, value] = f.split('=');
        if (value) {
          query[key] = value;
        }
      });

    if (query.id) {
      setUpdate(query.id);
      getData(query.id);
    }
  }, [location]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Novo conteúdo</h4>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <Form
            ref={formRef}
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Row>
              <Col lg={4}>
                <Image name="file" label="Foto" className="form-control" />
              </Col>
              <Col lg={8} />
              <Col lg={4}>
                <Input name="titulo" label="Título" className="form-control" />
              </Col>
              <Col lg={4}>
                <Select
                  name="tipo"
                  label="Tipo"
                  className="form-control form-select"
                  data={[
                    { value: '', label: 'Selecione' },
                    { value: 'noticia', label: 'Notícia' },
                    { value: 'blog', label: 'Blog' },
                  ]}
                />
              </Col>
              <Col lg={4}>
                <Select
                  name="status"
                  label="Status"
                  className="form-control form-select"
                  data={[
                    { value: 'Ativo', label: 'Ativo' },
                    { value: 'Inativo', label: 'Inativo' },
                  ]}
                />
              </Col>
              <Col lg={12}>
                <Editor name="descricao" label="Descrição" />
              </Col>
            </Row>
            <div className="d-flex justify-content-end mt-3 mb-3">
              {!!update && (
                <Button
                  className="btn btn-danger mr-3"
                  type="button"
                  onClick={() => {
                    handleDelete();
                  }}
                >
                  Excluir
                </Button>
              )}
              <Button type="submit" color="primary">
                Salvar
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </>
  );
}

export default NovoConteudo;
