import React, { useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { Button, Card, Col, Row } from 'reactstrap';

import { toast } from 'react-toastify';
import { Input, Editor } from '../../components/unform';
import api from '../../services/api';

function QuemSomos() {
  const formRef = useRef();

  useEffect(() => {
    async function getData() {
      const { data } = await api.get('/textos-site');
      if (data) {
        formRef?.current?.setData({
          ...data,
        });
      }
    }

    getData();
  }, []);

  async function handleSubmit(data) {
    const errors = {};

    Object.keys(data).forEach((f) => {
      if (!data[f]) {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      try {
        await api.post('/textos-site', data);

        toast.success('Dados salvos com sucesso!');
      } catch (error) {
        toast.error('Erro ao slavar dados!');
      }
    }
  }

  return (
    <>
      <Form
        ref={formRef}
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <Row>
          <Col lg={12}>
            <h4 className="mb-3">Início</h4>
          </Col>
        </Row>
        <Card className="px-3 py-3">
          <Row>
            <Col lg={12}>
              <Input
                name="titulo_inicio"
                label="Título"
                className="form-control"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Editor name="descricao_inicio" label="Descrição" />
            </Col>
          </Row>
        </Card>
        <Row>
          <Col lg={12}>
            <h4 className="mb-3">Categorias</h4>
          </Col>
        </Row>
        <Card className="px-3 py-3">
          <Row>
            <Col lg={12}>
              <Input
                name="titulo_categoria"
                label="Título"
                className="form-control"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Editor name="descricao_categoria" label="Descrição" />
            </Col>
          </Row>
        </Card>
        <Row>
          <Col lg={12}>
            <h4 className="mb-3">Quem somos</h4>
          </Col>
        </Row>
        <Card className="px-3 py-3">
          <Row>
            <Col lg={12}>
              <Editor name="descricao_sobrenos" label="Descrição" />
            </Col>
          </Row>
        </Card>
        <Row>
          <Col lg={12}>
            <h4 className="mb-3">Contato</h4>
          </Col>
        </Row>
        <Card className="px-3 py-3">
          <Row>
            <Col lg={6}>
              <Input
                name="contato_telefone"
                label="Telefone"
                className="form-control"
              />
            </Col>
            <Col lg={6}>
              <Input
                name="contato_email"
                label="E-mail"
                className="form-control"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Input
                name="contato_website_1"
                label="Website 1"
                className="form-control"
              />
            </Col>
            <Col lg={6}>
              <Input
                name="contato_website_2"
                label="Website 2"
                className="form-control"
              />
            </Col>
          </Row>
        </Card>
        <Row>
          <Col lg={12} className="d-flex justify-content-end">
            <Button color="primary" type="submit">
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default QuemSomos;
